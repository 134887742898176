import { render, staticRenderFns } from "./Boxes.vue?vue&type=template&id=76eb1604&"
import script from "./Boxes.vue?vue&type=script&lang=js&"
export * from "./Boxes.vue?vue&type=script&lang=js&"
import style0 from "./Boxes.vue?vue&type=style&index=0&module=true&lang=css&"
import style1 from "./Boxes.vue?vue&type=style&index=1&lang=css&"
import style2 from "./Boxes.vue?vue&type=style&index=2&lang=css&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports