<template>
	<div :class="$style['root']">
		<flickity ref="flickity" :class="$style['flickity']" :options="flickityOptions" v-if="packs.length > 0">
			<div :class="$style['pack']" v-for="pack in packs" :key="pack.id">
				<pack @resize="resize" :pack="pack" :lineup="lineups[pack.shortName]"/>
			</div>
		</flickity>

		<disclaimers :disclaimers="disclaimers" />
	</div>
</template>

<script>
	import axios from 'axios'
	import axiosRetry from 'axios-retry'
	import shuffle from 'shuffle-array'
	import { ResizeObserver } from 'resize-observer'
	import Flickity from 'vue-flickity'

	import Disclaimers from '@/components/Disclaimers'
	import Pack from '@/components/Pack'

	axiosRetry(axios)

	export default {
		components: {
			Flickity,

			Disclaimers,
			Pack,
		},

		created() {
			axios.get('https://data.dishformyrv.com/channel-boxes/packs.json').then(response => this.packs = response.data[this.type].filter(pack => this.packIds.includes(pack.id)))

			axios.get('https://data.dishformyrv.com/channel-boxes/disclaimers.json').then(response => this.disclaimers = response.data[this.type])

			axios.get('https://data.dishformyrv.com/channel-boxes/lineups.json').then(response => {
				Object.keys(response.data).forEach(packName => {
					shuffle(response.data[packName])
				})
				
				this.lineups = response.data
			})
		},

		data() {
			return {
				disclaimers: { body: [], full: [] },
				packs: [],
				lineups: [],

				flickityOptions: {
					groupCells: true,
					pageDots: false,
					watchCSS: true,
				},
			}
		},

		methods: {
			resize() {
				if (this.$refs.flickity) {
					this.$nextTick(() => {
						this.$refs.flickity.resize()
					})
				}
			}
		},

		mounted() {
			this.observer = new ResizeObserver(this.resize)
			this.observer.observe(document.body)
		},

		props: {
			packIds: {
				type: Array,
				default() { return [0, 1, 2, 3] },
			},

			type: {
				type: String,
				default: 'payg',
			},
		}
	}
</script>

<style module>
	.root {
		@apply bg-white text-black text-center font-sans text-base;
	}

	.row {
		@apply flex flex-wrap justify-center -mx-2;
	}

	.margin {
		@apply mx-2;
	}

	.pack {
		@apply relative w-5/6 px-2 max-w-xs;
	}

	.flickity {
		@apply mb-2;
	}

	.flickity::after {
		content: 'flickity';
		display: none;
	}

	@screen md {
		.pack {
			@apply w-1/2;
		}
	}

	@screen lg {
		.flickity {
			@apply flex -mx-1 mb-0;
		}

		.pack {
			@apply w-1/4 mx-1 px-0;
		}

		.flickity::after {
			content: '';
		}
	}

	@screen xl {
		.flickity {
			@apply -mx-4;
		}

		.pack {
			@apply mx-4;
		}
	}
</style>

<style>
	.flickity-button {
		@apply shadow-md bg-gray-100;
	}

	.flickity-prev-next-button {
		@apply h-8 w-8;
	}
</style>

<style>
	@font-face {
		font-family: proxima-nova;
		font-style: italic;
		font-weight: 400;
		font-display: swap;
		src: url("https://cdn2.hubspot.net/hubfs/1846077/fonts/proximanova/proximanova-57759f9fbca661ef8493f671c20106cca1b08c88.woff2") format("woff2"), 
			url("https://cdn2.hubspot.net/hubfs/1846077/fonts/proximanova/proximanova-d84e1604220047ec7c83c60c87a3425ffaffcf7f.woff") format("woff"), 
			url("https://cdn2.hubspot.net/hubfs/1846077/fonts/proximanova/proximanova-bbf1539be222d7fe4640fa8971c65e85c07c470b") format("opentype");
	}

	@font-face {
		font-family: proxima-nova;
		font-style: normal;
		font-weight: 300;
		font-display: swap;
		src: url("https://cdn2.hubspot.net/hubfs/1846077/fonts/proximanova/proximanova-ecbcf99614dd78addb6474c9bb46f06550dd0285.woff2") format("woff2"), 
			url("https://cdn2.hubspot.net/hubfs/1846077/fonts/proximanova/proximanova-c08e3aafe9b21e4e0f05a8b7184248d71f819b3c.woff") format("woff"), 
			url("https://cdn2.hubspot.net/hubfs/1846077/fonts/proximanova/proximanova-763e2c7eda2e38cc72e9787042b3855ea1c79105") format("opentype");
	}

	@font-face {
		font-family: proxima-nova;
		font-style: normal;
		font-weight: 400;
		font-display: swap;
		src: url("https://cdn2.hubspot.net/hubfs/1846077/fonts/proximanova/proximanova-b178c0c2d00114bb5e2c276d4489695cccdd6967.woff2") format("woff2"), 
			url("https://cdn2.hubspot.net/hubfs/1846077/fonts/proximanova/proximanova-98924b01af01a04387040ef9cf83f5b6fd26c117.woff") format("woff"), 
			url("https://cdn2.hubspot.net/hubfs/1846077/fonts/proximanova/proximanova-cf06fe4c3be8f30d33fa79b3d6770be5ff6db287") format("opentype");
	}

	@font-face {
		font-family: proxima-nova;
		font-style: normal;
		font-weight: 700;
		font-display: swap;
		src: url("https://cdn2.hubspot.net/hubfs/1846077/fonts/proximanova/proximanova-361ed78bf309b06305b4ed718f9b027160bd4ee8.woff2") format("woff2"), 
			url("https://cdn2.hubspot.net/hubfs/1846077/fonts/proximanova/proximanova-a9f5393402174551a2ff00c9c20739b82e138c53.woff") format("woff"), 
			url("https://cdn2.hubspot.net/hubfs/1846077/fonts/proximanova/proximanova-a293e8efa42c475fc6c36390e8f12aba257e5e29") format("opentype");
	}

	html {
		font-size: 16px!important;
	}
</style>
