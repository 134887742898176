<template>
	<div :style="{ height: height + 'px' }">
		<simple-bar :class="$style['full-height']">
			<div :class="$style['root']">
				<img :class="$style['img']" v-for="(image, index) in images" :key="index" :src="image">

				<div :class="$style['overlay']" :style="{ 
					height: height + 'px',
					background: 'linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,0.9) 30%)',
					boxSizing: 'content-box',
				}">
					<div :style="`paddingBottom: ${height/2 - buttonHeight/2 - 16}px;`">
						<div ref="button">
							<p :class="[$style['reset'], $style['button']]" @click="downloadLineup" :style="{ backgroundColor: color }">
								{{ download.text == '' ? 'Download Full Lineup' : download.text }}
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" :class="$style['icon']"  class="text-base h-5 w-5 ml-1">
									<path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4m4-5l5 5 5-5m-5 5V3"></path>
								</svg>
							</p>
						</div>
					</div>
				</div>
			</div>
		</simple-bar>
	</div>
</template>

<script>
	import SimpleBar from 'simplebar-vue'
	
	export default {
		components: {
			SimpleBar,
		},

		computed: {
			color() {
				return this.pack.color
			},

			download() {
				return this.pack.download
			},

			shortName() {
				return this.pack.shortName
			},

			images() {
				return this.lineup.map(image => `https://get.dishformyrv.com/hubfs/ChannelBoxes/Channel/Logo/${image}.png`)
			}
		},

		data() {
			return {
				buttonHeight: 0,
			}
		},

		methods: {
			downloadLineup() {
				this.$emit('event', { name: this.shortName, action: 'download lineup'})
				window.open(this.download.link, '_blank')
			},
		},

		mounted: function () {
			this.buttonHeight = this.$refs['button'].clientHeight
		},

		props: {
			height: {
				type: Number,
				default: 0,
			},

			pack: {
				type: Object,
			},

			lineup: {
				type: Array,
				default() { return [] },
			},
		}
	}
</script>

<style src="simplebar/dist/simplebar.css"></style>

<style module>
	.reset {
		@apply m-0 p-0 text-base font-normal tracking-normal leading-tight font-sans !important;
	}

	.full-height {
		@apply h-full;
	}
	
	.root {
		@apply flex flex-wrap pb-6 pt-3 justify-around relative overflow-x-hidden;
	}

	.img {
		@apply w-auto mx-3 my-2;
	}

	.overlay {
		@apply absolute bottom-0 left-0 w-full flex items-end justify-center;
	}

	.button {
		@apply cursor-pointer px-4 py-2 mx-4 rounded-lg text-white no-underline flex items-center !important;
	}

	.button:hover {
		@apply underline !important;
	}

	.icon {
		@apply text-base h-5 w-5 ml-1;
	}
</style>
