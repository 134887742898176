<template>
	<div :class="$style['root']">
		<div :class="$style['button']" :style="{ backgroundColor: color }">
			<p :class="$style['text']" @click="$emit('view')"><slot/></p>
		</div>
	</div>
</template>

<script>
	export default {
		props: [
			'color',
		],
	}
</script>

<style module>
	.root {
		@apply -mt-3 mb-0 flex flex-col;
	}

	.button {
		@apply py-3 text-xl flex rounded-t relative z-10;
	}

	.text {
		@apply my-0 mx-auto cursor-pointer p-0 text-base font-normal tracking-normal leading-tight font-sans text-white flex items-center !important;
	}

	.text:hover {
		@apply underline;
	}
</style>
