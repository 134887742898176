<template>
	<div>
		<span :class="$style['xs-text']" v-for="(disclaimer, index) in disclaimers.body" :key="index">
			<span v-if="disclaimer.title" :class="[$style['bold'], $style['xs-text']]">{{ disclaimer.title }}</span> {{ disclaimer.body }}
		</span>

		<template v-if="disclaimersOpened">
			<span :class="$style['xs-text']" v-for="(disclaimer, index) in disclaimers.full" :key="index">
				<span :class="[$style['bold'], $style['xs-text']]">{{ disclaimer.title }}</span> {{ disclaimer.body }}
			</span>
		</template>
		
		<div :class="{ [$style['margin-top']]: disclaimersOpened || disclaimers.body.length > 0 }">
			<span :class="$style['toggle']" @click="disclaimersOpened = !disclaimersOpened">{{ termsLink }}</span>
		</div>
	</div>
</template>

<script>
	export default {
		computed: {
			termsLink() {
				const text = `${this.disclaimers.body.length > 0 ? 'Additional ' : ''}Terms and Conditions`

				return this.disclaimersOpened ? `Hide ${text} ↑` : `View ${text} ↓`
			},
		},

		data() {
			return {
				disclaimersOpened: false,
			}
		},

		props: {
			disclaimers: {
				type: Object,
				default() { return { body: [], full: [] }}
			}
		}
	}
</script>

<style module>
	.xs-text {
		@apply text-xs;
	}

	.bold {
		@apply font-bold;
	}

	.margin-top {
		@apply mt-2;
	}

	.toggle {
		@apply text-dish-red cursor-pointer italic;
	}
</style>
