<template>
	<div :class="$style['root']">
		<p :class="[$style['reset'], $style['name']]">{{ name }}</p>
		<p :class="[$style['reset'], $style['count']]">{{ count }}</p>
		<p :class="[$style['reset'], $style['channels']]">Channels</p>

		<div :class="$style['imgs']">
			<img :class="$style['img']" v-for="(img, index) in thumbnails" :key="index" :src="`https://get.dishformyrv.com/hubfs/ChannelBoxes/Channel/Thumbnail/${img}`">
		</div>

		<div :class="[$style['price']]">
			<p :class="[$style['reset'], $style['dollars']]">{{ price.dollar }}</p>
			<p :class="[$style['reset'], $style['per']]">
				<span :class="[$style['cents']]">{{ price.cent }}</span>
				<span :class="[$style['month']]">/mo.</span>
			</p>
		</div>
	</div>
</template>

<script>
	export default {
		computed: {
			name() {
				return this.pack.name
			},

			count() {
				return this.pack.count
			},

			thumbnails() {
				return this.pack.thumbnails
			},

			price() {
				return this.pack.price
			},
		},

		props: [
			'pack',
		],
	}
</script>

<style module>
	.reset {
		@apply m-0 p-0 text-base font-normal tracking-normal leading-tight font-sans !important;
	}

	.root {
		@apply px-2 py-4;
	}

	.name {
		@apply text-2xl !important;
	}

	.count {
		@apply text-7xl font-light !important;
	}

	.channels {
		@apply uppercase -mt-3 !important;
	}

	.imgs {
		@apply my-4;
	}

	.img {
		@apply mx-1 h-10 w-10 inline;
	}

	.sign {
		@apply text-xl !important;
	}

	.price {
		@apply flex justify-center items-center;
	}

	.dollars {
		@apply text-6xl align-top leading-none font-light !important;
	}

	.dollars::before {
		content: '$';
		font-size: 0.5em;
		vertical-align: super;
	}

	.per {
		@apply inline-flex flex-col leading-none items-start !important;
	}

	.cents {
		@apply text-3xl !important;
	}

	.month {
		@apply text-base !important;
	}
</style>
