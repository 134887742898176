<template>
	<div :class="$style['root']" :style="{ borderColor: color }">
		<overview ref="overview" v-show="!showDetails" :pack="pack"/>

		<full-view ref="details" @event="downloadLineup" v-show="showDetails" :pack="pack" :height="height" :short-name="shortName" :lineup="lineup" />

		<control :class="$style['desktop']" ref="footer" :color="color" :text="footerText" @view="changeContent">{{ footerText }}</control>
		<control :class="$style['mobile']" ref="footer" :color="color" text="Download Pack Lineup" @view="downloadLineup">
			Download Full Lineup<svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" :class="$style['icon']"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4m4-5l5 5 5-5m-5 5V3"></path></svg>
		</control>
	</div>
</template>

<script>
	import Overview from './pack/Overview'
	import FullView from './pack/FullView'
	import Control from './pack/Control'

	export default {
		components: {
			Overview,
			FullView,
			Control,
		},

		data() {
			return {
				showDetails: false,
				height: 0,

				isIE: false,
				footerText: 'View Channels',
			}
		},

		methods: {
			changeContent() {
				this.sendEvent(this.shortName, this.showDetails ? 'view overview' : 'view details')

				this.height = this.$refs['overview'].$el.clientHeight

				this.showDetails = !this.showDetails
				this.footerText = this.showDetails ? 'Return to Overview' : 'View Channels'
			},

			downloadLineup() {
				this.sendEvent(this.pack.shortName, 'download lineup')
				window.open(this.pack.download.link, '_blank')
			},

			sendEvent(name, action) {
				const dataLayer = window.dataLayer = window.dataLayer || []
				dataLayer.push({
					'event': 'channelbox-interaction',
					'channelbox-pack': name,
					'channelbox-action': action,
				})
			},
		},

		mounted() {
			this.$emit('mounted')
		},

		props: [
			'pack',
			'lineup',
		],

		computed: {
			color() {
				return this.pack.color
			},

			download() {
				return this.pack.download
			},

			shortName() {
				return this.pack.shortName
			},
		},
	}
</script>

<style module>
	.root {
		@apply flex-grow flex-shrink-0 flex flex-col shadow-md mb-4 border-solid;

		border-width: 4px 0 0 0;
	}

	.icon {
		@apply text-base h-5 w-5 ml-1;
	}

	.desktop {
		@apply hidden;
	}

	@screen lg {
		.mobile {
			@apply hidden;
		}

		.desktop {
			@apply block;
		}
	}
</style>
