<template>
	<div class="bg-gray-100">
		<header class="sticky top-0 z-20">
			<div class="bg-white shadow py-4 relative z-10 lg:py-3">
				<div class="container mx-auto px-4 flex items-center justify-between md:px-8">
					<img class="h-4 sm:h-8 lg:h-10" :src="require('@/assets/dish-outdoors.svg')">
					<a class="text-dish-red font-bold no-underline sm:text-3xl lg:text-4xl" :href="phoneUrl">{{ phoneNumber }}</a>
				</div>
			</div>
		</header>

		<div class="container mx-auto">
			<div class="sm:shadow bg-white">
				<div class="bg-cover bg-left-bottom bg-no-repeat" :style="`background-image: linear-gradient(rgba(0,0,0,0.75) 6rem, transparent 50%), url('${require('@/assets/LearnMore/header.jpg')}');`">
					<section class="container mx-auto pt-8 pb-32 px-4 md:px-0">
						<h1 class="mb-32 font-bold text-white text-center tracking-wider leading-tight text-3xl sm:text-4xl md:text-5xl lg:text-6xl">
							<span>Pay As You Go </span>
							<span class="relative inline-block">
								<div class="absolute left-0 bottom-0 h-1 bg-dish-red w-full z-0 mb-1 md:mb-2 md:h-2 lg:mb-3" />
								<span class="relative z-10">Live TV</span>
							</span>
						</h1>
						<div class="py-8"></div>
					</section>
				</div>

				<section class="px-8 pt-12 pb-4 text-center md:pb-12 lg:py-12 relative">
					<div id="learn-more" class="absolute top-0 -mt-2 lg:-mt-12"></div>
					<h1 class="text-3xl font-semibold leading-none sm:text-4xl xl:text-5xl">Get DISH Outdoors in 3 Easy Steps</h1>

					<div class="mt-2 md:mt-0 lg:flex lg:-ml-8">
						<a href="#wally" class="block no-underline text-black pb-5 mt-8 max-w-md mx-auto lg:flex-1 lg:ml-8">
							<div class="shadow-md relative xl:h-full">
								<div class="relative" @click.prevent.stop>
									<div class="w-full relative" style="padding-bottom: 56.25%;">
										<iframe class="absolute w-full h-full"  src="https://www.youtube.com/embed/bTkb3GmdHZ4?rel=0&modestbranding=1&enablejsapi=1" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
									</div>
								</div>
								<div class="px-4 pt-4 pb-6 lg:pb-8 lg:pt-5">
									<div class="absolute bottom-0 w-full left-0 flex justify-center -mb-5">
										<p class="text-2xl font-bold bg-dish-red text-white rounded-full px-4 leading-none py-1 flex justify-center items-center flex-shrink-0 lg:text-3xl">Step 1</p>
									</div>
									<div class="flex justify-center items-center lg:items-start">
										<h3 class="tracking-tight text-lg font-bold leading-none lg:text-xl xl:text-2xl xl:max-w-xs">Get a DISH Wally® HD Receiver and accessories.</h3>
									</div>
									<p class="leading-tight mt-2 xl:text-xl">Create your custom entertainment solution and enjoy built-in apps.</p>
								</div>
							</div>
						</a>
						<a href="#antenna" class="block no-underline text-black pb-5 mt-8 max-w-md mx-auto lg:flex-1 lg:ml-8">
							<div class="shadow-md relative xl:h-full">
								<div class="relative" @click.prevent.stop>
									<div class="w-full relative" style="padding-bottom: 56.25%;">
										<iframe class="absolute w-full h-full" src="https://www.youtube.com/embed/0Oleb0NVeek?rel=0&modestbranding=1&enablejsapi=1" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
									</div>
								</div>
								<div class="px-4 pt-4 pb-6 lg:pb-8 lg:pt-5">
									<div class="absolute bottom-0 w-full left-0 flex justify-center -mb-5">
										<p class="text-2xl font-bold bg-dish-red text-white rounded-full px-4 leading-none py-1 flex justify-center items-center flex-shrink-0 lg:text-3xl">Step 2</p>
									</div>
									<div class="flex justify-center items-center lg:items-start">
										<h3 class="tracking-tight text-lg font-bold leading-none lg:text-xl xl:text-2xl xl:max-w-xs">Add a DISH satellite antenna.</h3>
									</div>
									<p class="leading-tight mt-2 xl:text-xl">Choose the antenna that's right for you and power one or multiple TVs</p>
								</div>
							</div>
						</a>
						<a href="#programming" class="block no-underline text-black pb-5 mt-8 max-w-md mx-auto lg:flex-1 lg:ml-8">
							<div class="shadow-md relative xl:h-full">
								<div class="relative" @click.prevent.stop>
									<div class="w-full relative" style="padding-bottom: 56.25%;">
										<iframe class="absolute w-full h-full"  src="https://www.youtube.com/embed/8VbPUaovRSw?rel=0&modestbranding=1&enablejsapi=1" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
									</div>
								</div>
								<div class="px-4 pt-4 pb-6 lg:pb-8 lg:pt-5">
									<div class="absolute bottom-0 w-full left-0 flex justify-center -mb-5">
										<p class="text-2xl font-bold bg-dish-red text-white rounded-full px-4 leading-none py-1 flex justify-center items-center flex-shrink-0 lg:text-3xl">Step 3</p>
									</div>
									<div class="flex justify-center items-center lg:items-start">
										<h3 class="tracking-tight text-lg font-bold leading-none lg:text-xl xl:text-2xl xl:max-w-xs">Select Programming and call <a class="inline-block text-dish-red" :href="phoneUrl">{{ phoneNumber }}</a> to activate.</h3>
									</div>
									<p class="leading-tight mt-2 xl:text-xl">Pay As You Go from $62.99/mo*, with no contracts or commitments.</p>
								</div>
							</div>
						</a>
					</div>
				</section>

				<aside class="py-4 px-6 text-gray-900 lg:flex" style="background-color: #eee;">
					<div class="mx-auto max-w-lg lg:w-2/5">
						<div class="relative">
							<p class="text-dish-red text-7xl absolute top-0 left-0 -ml-6 leading-none -mt-8 opacity-25 sm:-ml-10" style="transform:scale(3); transform-origin:top left;">&#8220;</p>
							<p class="pl-2 relative font-medium tracking-wide sm:text-xl italic lg:text-lg">The DISH Outdoors service is the best thing going for RVers. So easy to start, stop, and set up. And good support. We have recommended it to several RVers.</p>
						</div>
						<div class="mt-2 flex items-center">
							<p class="ml-auto font-semibold">&ndash; Jody M, Idaho</p>
						</div>
					</div>
					<div class="hidden mx-auto max-w-lg lg:w-2/5 lg:block">
						<div class="relative">
							<p class="text-dish-red text-7xl absolute top-0 left-0 -ml-6 leading-none -mt-8 opacity-25 sm:-ml-10" style="transform:scale(3); transform-origin:top left;">&#8220;</p>
							<p class="pl-2 relative font-medium tracking-wide sm:text-xl italic lg:text-lg">We love our DISH Outdoors for our camper. Great value and price and we LOVE that we are not in contract and can turn off and on as we please..</p>
						</div>
						<div class="mt-2 flex items-center">
							<p class="ml-auto font-semibold">&ndash; Lisa R, Nebraska</p>
						</div>
					</div>
				</aside>

				<section class="px-4 text-center sm:py-12 md:px-6 lg:px-8 relative">
					<div id="wally" class="absolute top-0 -mt-12 lg:-mt-20"></div>
					<div class="py-4">
						<h2 class="font-semibold leading-none text-3xl sm:text-4xl xl:text-5xl"><span class="text-dish-red">Step 1:</span> Get a Wally HD Receiver and accessories</h2>
						<p class="mt-2 text-xl leading-tight sm:text-2xl xl:text-3xl">The Wally powers all of your entertainment needs, from live TV to Netflix and more.</p>

						<div class="mt-4 items-center justify-around lg:flex lg:mt-8">
							<div class="text-center mt-4 md:px-4 lg:px-2 lg:mt-0 lg:w-1/2 lg:text-left">
								<a target="_blank" href="https://www.dishformyrv.com/dish-wally-hd-receiver/">
									<img class="mt-6 sm:w-2/3 sm:mx-auto lg:w-full" :src="require('@/assets/horizontal-wally.png')">
								</a>
							</div>
							<div class="mt-8 lg:mt-0 lg:px-2 lg:w-1/2">
								<p class="sm:text-lg text-left">With built in apps, channel search features, and Bluetooth capability, the Wally gives you the comforts of TV at home, on the go!</p>

								<h5 class="mt-4 text-lg text-left sm:text-xl font-semibold">Features</h5>
								<ul class="pl-6 list-disc text-left sm:text-lg leading-tight -mt-2">
									<li class="mt-2">Watch live TV in crystal-clear HD</li>
									<li class="mt-2">Connect to Wi-Fi to access built-in apps like Netflix, Pandora, Weather Channel and more</li>
									<li class="mt-2">Save space with the world's smallest mobile receiver</li>
									<li class="mt-2">Operate from up to 200' away, even through walls and cabinetry, with the RF remote</li>
									<li class="mt-2">Purchase one Wally HD receiver for each TV you want to power</li>
								</ul>
							</div>
						</div>

						<p class="mt-8 text-xl leading-tight sm:text-2xl lg:mt-16 xl:text-3xl">Enhance your entertainment experience with these Wally accessories</p>

						<div class="pb-8 sm:flex flex-wrap justify-around">
							<div class="mt-12 flex flex-col w-full sm:px-8 sm:w-1/2 md:max-w-xs lg:w-1/4 lg:px-2 lg:max-w-full">
								<a target="_blank" href="https://www.dishformyrv.com/dish-wally-wi-fi-usb-adapter/">
									<img class="w-32 mx-auto sm:w-48" :src="require('@/assets/accessories/Wifi-Adapter.png')">
								</a>
								<h3 class="mt-2 font-bold text-lg sm:text-2xl">Wi-Fi Adapter</h3>
								<p class="sm:text-lg">Connect your Wally to a mobile hotspot or Wi-Fi network to access On Demand content and enjoy built-in apps like Netflix.<sup>1</sup></p>

								<div class="mt-4 sm:mt-8"></div>
								<div class="mt-auto">
								</div>
							</div>
							<div class="mt-12 flex flex-col w-full sm:px-8 sm:w-1/2 md:max-w-xs lg:w-1/4 lg:px-2 lg:max-w-full">
								<a target="_blank" href="https://www.dishformyrv.com/1-tb-dvr-upgrade-expansion/">
									<img class="w-32 mx-auto sm:w-48" :src="require('@/assets/accessories/DVR.png')">
								</a>
								<h3 class="mt-2 font-bold text-lg sm:text-2xl">DVR Upgrade</h3>
								<p class="sm:text-lg">Record up to 100 hours of HD content to enjoy anytime. Watch your favorite shows and movies with no Wi-Fi or satellite connection necessary.<sup>2</sup></p>

								<div class="mt-4 sm:mt-8"></div>
								<div class="mt-auto">
								</div>
							</div>
							<div class="mt-12 flex flex-col w-full sm:px-8 sm:w-1/2 md:max-w-xs lg:w-1/4 lg:px-2 lg:max-w-full">
								<a target="_blank" href="https://www.dishformyrv.com/dish-wally-over-the-air-tuner/">
									<img class="w-32 mx-auto sm:w-48" :src="require('@/assets/accessories/OTA.png')">
								</a>
								<h3 class="mt-2 font-bold text-lg sm:text-2xl">Over-The-Air Digital Tuner</h3>
								<p class="sm:text-lg">Integrate channels from your OTA antenna to watch local news, weather and the primetime shows you love without switching inputs.</p>

								<div class="mt-4 sm:mt-8"></div>
								<div class="mt-auto">
								</div>
							</div>
							<div class="mt-12 flex flex-col w-full sm:px-8 sm:w-1/2 md:max-w-xs lg:w-1/4 lg:px-2 lg:max-w-full">
								<a target="_blank" href="https://www.dishformyrv.com/dish-wally-bluetooth-usb-adapter/">
									<img class="w-32 mx-auto sm:w-48" :src="require('@/assets/accessories/Bluetooth.png')">
								</a>
								<h3 class="mt-2 font-bold text-lg sm:text-2xl">Bluetooth Adapter®</h3>
								<p class="sm:text-lg">Connect to Bluetooth devices like wireless speakers or headphones for even louder, crisper audio.</p>

								<div class="mt-4 sm:mt-8"></div>
								<div class="mt-auto">
								</div>
							</div>
						</div>
						
						<div class="text-xs text-center pb-4 sm:pb-0">
							<p><sup>1</sup>Access to built-in apps requires Wi-Fi network connection and Wally Wi-Fi Adapter Accessory. Netflix access requires active streaming membership.</p>
							<p><sup>2</sup>DVR may require a one-time $40 activation fee.</p>
						</div>
					</div>
				</section>


				<section class="px-4 py-4 text-center sm:py-12 md:px-6 lg:px-8 relative">
					<div id="antenna" class="absolute top-0 -mt-14 lg:-mt-12"></div>
					<h2 class="font-semibold leading-none text-3xl sm:text-4xl xl:text-5xl"><span class="text-dish-red">Step 2:</span> Add a DISH Satellite Antenna</h2>
					<p class="mt-2 text-xl leading-tight sm:text-2xl xl:text-3xl">Choose the antenna that's right for you and power one or multiple TVs.</p>
					<flickity ref="antennas" class="antennas-flickity -mx-4 mt-4 lg:flex lg:mt-4" :options="flickityOptions">
						<div class="w-5/6 py-4 px-2 max-w-xs min-h-full sm:w-5/12 lg:pb-0 lg:flex-grow lg:px-0" v-for="(antenna, index) in antennas" :key="index">
							<div class="shadow py-2 h-full lg:shadow-none">
								<a target="_blank" :href="antenna.link">
									<img @load="$refs['antennas'].resize()" class="mx-auto lg:w-3/4" :src="antenna.img">
								</a>
								<div class="px-2 pb-2 mt-4 flex flex-col leading-tight" :class="index == 0 ? '' : 'lg:border-l-2 border-gray-700'">
									<h4 class="font-bold text-xl xl:text-2xl">{{ antenna.name }}</h4>
									<p class="text-lg mt-2 mb-auto">{{ antenna.tvs }} TV Option</p>
									<p class="text-lg mt-2 h-12 lg:text-base lg:h-auto xl:text-lg">{{ antenna.tagline }}</p>
								</div>
							</div>
						</div>
					</flickity>
				</section>

				<section class="py-4 px-4 text-center sm:py-12 md:px-8 relative">
					<div id="programming" class="absolute top-0 -mt-14 lg:-mt-24"></div>
					<h2 class="font-semibold leading-none text-3xl sm:text-4xl xl:text-5xl"><span class="text-dish-red">Step 3:</span> Select Programming &amp; Call to Activate</h2>
					<p class="mt-2 text-xl leading-tight sm:text-2xl xl:text-3xl">Only DISH offers month-to-month programming at an affordable price.</p>
					
					<div class="mt-8 mx-auto sm:flex justify-center items-center lg:w-5/6 xl:w-2/3">
						<div class="sm:w-1/2 sm:pr-4 lg:pr-8">
							<div class="">
								<p class="text-xl">Pay As You Go Packages Starting At</p>
								<div class="border-t-2 border-dish-red mt-4 pt-4 flex flex-col justify-between items-center md:flex-row">
									<div class="text-left font-black leading-none flex-shrink-0">
										<p class="italic text-3xl">Only</p>
										<div class="flex items-center">
											<p class="self-start text-5xl -mr-2 leading-snug">$</p>
											<p style="font-size: 7.25rem;">62</p>
											<div class="">
												<p class="text-5xl">99</p>
												<p class="text-3xl">/mo.</p>
											</div>
										</div>
									</div>

									<div class="sm:pl-4">
										<p class="leading-none">50+ starting channels</p>
									</div>
								</div>

								<div class="border-t-2 border-dish-red mt-4 pt-4 flex justify-between items-center md:mt-2">
									<div class="">
										<img class="h-6" :src="require('@/assets/home/logos/tnt.svg')">
									</div>
									<div class="">
										<img class="h-6" :src="require('@/assets/home/logos/hgtv.svg')">
									</div>
									<div class="">
										<img class="h-6" :src="require('@/assets/home/logos/cartoon-network.svg')">
									</div>
									<div class="">
										<img class="h-6" :src="require('@/assets/home/logos/history.svg')">
									</div>
									<div class="">
										<img class="h-6" :src="require('@/assets/home/logos/tbs.svg')">
									</div>
									<div class="hidden md:block">
										<img class="h-6" :src="require('@/assets/home/logos/food-network.svg')">
									</div>
									<div class="hidden md:block">
										<img class="h-6" :src="require('@/assets/home/logos/fx.svg')">
									</div>
									<div class="">
										<a class="no-underline block text-black" target="_blank" href="https://f.hubspotusercontent20.net/hubfs/1846077/dishoutdoors/downloads/DISH%20Outdoors%20Programming.pdf">
											<p class="text-sm leading-none tracking-wide">Plus<br>More!</p>
										</a>
									</div>
								</div>
							</div>
						</div>
						<div class="mt-8 text-left sm:mt-0 sm:w-1/2 sm:pl-4 lg:pl-8">
							<ul class="list-disc pl-6 -mt-2 sm:pl-4 sm:text-xl">
								<li class="mt-2">Only pay for the months you use!</li>
								<li class="mt-2">No activation, disconnect, or restart fees.</li>
								<li class="mt-2">No Wi-Fi needed.</li>
								<li class="mt-2">No credit check.</li>
								<li class="mt-2">Already a DISH subscriber? Add DISH Outdoors to your bill for only $5/mo. per receiver.</li>
							</ul>
						</div>
					</div>

					<div class="flex justify-center sm:block">
						<a @click="sendEvent('See Programming Packages')" class="no-underline inline-block mt-4 bg-dish-red text-white py-2 px-4 font-semibold rounded sm:text-xl sm:mt-6 lg:py-1 lg:text-lg" target="_blank" href="https://f.hubspotusercontent20.net/hubfs/1846077/dishoutdoors/downloads/DISH%20Outdoors%20Programming.pdf">See Programming Packages</a>
					</div>

					<p class="mt-6 text-xl leading-tight font-semibold text-left sm:text-center sm:mt-12 sm:text-2xl xl:text-3xl">Looking for local channels? Find them with DISH Outdoors.</p>
					<p class="mt-2 text-left sm:text-center sm:text-lg">We know how important it is to stay connected. DISH Outdoors is committed to keeping you in touch with live local news coverage and weather forecasts, as well as the primetime shows you love, wherever you roam. Simply use the MyDISH app to verify your location.</p>
				</section>

				<section class="container mx-auto py-8 md:pt-16 px-4 md:px-6 xl:px-8" id="programming">
					<h2 class="text-2xl font-light mx-auto text-center mb-2 md:mb-0 md:text-5xl">Choose The Programming Package Right For You</h2>
					<p class="mb-8 text-center text-lg md:text-xl">Build your package with the Flex Pack or get more channels with our America's Top Packages and Pay-As-You-Go based on 30-day increments. If you already have DISH as your home provider, pay only $5 a month for an additional mobile receiver for your RV.</p>

					<channel-boxes/>

					<p class="text-center mt-8 text-2xl md:text-3xl">Call <a class="font-semibold text-dish-primary no-underline" href="tel:18889958695">1-888-995-8695</a> for more information</p>
				</section>

				<section class="bg-dish-red py-4">
					<img class="h-8 w-full" style="margin-left: auto; margin-right: auto;" :src="require('@/assets/dish-outdoors-white.svg')">
				</section>
			</div>
		</div>
	</div>
</template>

<script>
	import ChannelBoxes from '@/components/Boxes'
	import Flickity from 'vue-flickity'

	
	export default {
		components: {
			ChannelBoxes,
			Flickity,
		},

		computed: {
			phoneUrl() {
				return `tel:${this.phoneNumber}`
			},
		},

		data() {
			return {
				flickityOptions: {
					groupCells: true,
					pageDots: false,
					watchCSS: true,
				},

				antennas: [
					{ img: require('@/assets/Playmaker.png'), name: 'DISH Playmaker®', tagline: 'Smallest portable satellite antenna', tvs: 1, link: 'https://www.dishformyrv.com/new-dish-playmaker-bundle-with-wally/' },
					{ img: require('@/assets/Tailgater.png'), name: 'DISH Tailgater®', tagline: 'Integrated handle for portability', tvs: 1, link: 'https://www.dishformyrv.com/dish-tailgater-satellite-antenna-bundle-with-wally/' },
					{ img: require('@/assets/Playmaker-Dual-Bundle.png'), name: `DISH Playmaker® Dual`, tagline: 'Sleek and compact design', tvs: 2, link: 'https://www.dishformyrv.com/dish-playmaker-dual-satellite-antenna-bundle-with-wally-white/' },
					{ img: require('@/assets/Tailgater-Pro.png'), name: 'DISH Tailgater® Pro', tagline: 'Ultramodern, clear cover', tvs: 2, link: 'https://www.dishformyrv.com/dish-tailgater-pro-satellite-antenna-bundle-with-wally/' },
				],

				mobileMenuOpen: false,

				phoneNumber: '1-888-995-8695',
			}
		},
	}
</script>

<style scoped>
	.price::before {
		content: '$';
		font-size: .5em;
		vertical-align: super;
	}



	.antennas-flickity::after {
		content: 'flickity';
		display: none;
	}

	@screen lg {
		.antennas-flickity::after {
			content: '';
		}
	}
</style>
